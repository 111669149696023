// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-notes-js": () => import("/opt/build/repo/src/templates/notes.js" /* webpackChunkName: "component---src-templates-notes-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-frontend-apps-js": () => import("/opt/build/repo/src/pages/frontend-apps.js" /* webpackChunkName: "component---src-pages-frontend-apps-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-skills-js": () => import("/opt/build/repo/src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-writing-js": () => import("/opt/build/repo/src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

